import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import logo from '../assets/dev_logo.png'


class NavigationBar extends Component {
  render() {
    return (
        <nav
            className='navbar navbar-expand-lg fixed-top navbar-white bg-white'
            style={{boxShadow: '2px 2px 5px rgba(0, 0, 0, 0)'}}
        >
            <a
                className='navbar-brand'
                href='/'
                style={{
                    color:'rgba(0,0,0,1)',
                    
                }}
            ><img src={logo} width='50px' style={{marginRight: '5px'}} alt='Logo' />DELTA EMERALD</a>
            
            {/* collapse/expand button */}
            <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon'>
                    <FontAwesome name='bars' size='2x' />
                </span>
            </button>

            {/* collapsible links */}
            <div className='collapse navbar-collapse' id='navbarNav'>



                <ul className='nav navbar-nav ml-auto'>

                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle custom-dropdown-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            THE FIRM
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" href="/firm/objective">Our Objective</a>
                            <a className="dropdown-item" href="/firm/people">Our People</a>
                            <a className="dropdown-item" href="/firm/values">Our Values</a>
                            <a className="dropdown-item" href="/firm/portfolio">Our Portfolio</a>
                            <a className="dropdown-item" href="/firm/faq">FAQ</a>
                        </div>
                    </div>
                    
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle custom-dropdown-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            WHAT WE DO
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" href="/what-we-do/private-equity">Private Equity</a>
                            <a className="dropdown-item" href="/what-we-do/proprietary-data">Proprietary Data</a>
                            <a className="dropdown-item" href="/what-we-do/digital-infrastructure">Digital Infrastructure</a>
                        </div>
                    </div>

                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle custom-dropdown-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            INSIGHTS
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" href="/insights/view-all">View All</a>
                            <a className="dropdown-item" href="/insights/magic-wand">Magic Wand</a>
                            <a className="dropdown-item" href="https://deltaemerald.substack.com/" target="_blank" rel="noreferrer">Delta Dispatch</a>
                            <a className="dropdown-item" href="https://www.cannadatacon.com" target="_blank" rel="noreferrer">CannaDataCon</a>
                        </div>
                    </div>

                    <div className="">
                        <a href="https://www.cannadatacon.com" target="_blank" rel="noreferrer">
                            <button className="btn btn-secondary custom-dropdown-btn" type="button">
                                CANNADATACON
                            </button>
                        </a>
                    </div>
                    <div className="">

                        <a href="https://deltaemerald.substack.com/" target="_blank" rel="noreferrer">
                            <button className="btn btn-secondary custom-dropdown-btn" type="button">
                                DISPATCH
                            </button>
                        </a>
                    </div>
                </ul>
            </div>
        </nav>
    )
  }
}


export default NavigationBar;
