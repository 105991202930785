import React, { Component } from 'react';

import adaviv from '../assets/portfolio_logos/adaviv.jpg'
import blaze from '../assets/portfolio_logos/blaze.png'
import brc from '../assets/portfolio_logos/brc_logo.png'
import cellibre from '../assets/portfolio_logos/cellibre.jpg'
import flyers from '../assets/portfolio_logos/flyers_logo.jpg'
import hca from '../assets/portfolio_logos/hca.png'
import henrys from '../assets/portfolio_logos/henrys.png'
import jane from '../assets/portfolio_logos/jane.png'
import leafly from '../assets/portfolio_logos/leafly.png'
import nabis from '../assets/portfolio_logos/nabis.jpg'
import trym from '../assets/portfolio_logos/trym.png'
import vangst from '../assets/portfolio_logos/vangst.png'
import zoltrain from '../assets/portfolio_logos/zoltrain.png'


class Porfolio extends Component {
  render() {
    return (
        <div id='Porfolio' className='container custom-section-container'>
            <h1 className="custom-page-title">
                PUBLICLY ANNOUNCED INVESTMENTS
            </h1>
            <br/>
            <div className='row'>
                
                {/* adaviv */}
                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://adaviv.com/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={adaviv}
                                    style={{
                                        margin:'0px',
                                        width:'90px'
                                    }}
                                    alt='adaviv'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                {/* blaze */}
                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://www.blaze.me/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={blaze}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'30px',
                                        width:'120px'
                                    }}
                                    alt='blaze'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                {/* brc */}
                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://biopharmaresearchco.com/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={brc}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'0px',
                                        width:'100px'
                                    }}
                                    alt='brc'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                {/* cellibre */}
                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://www.cellibre.com/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={cellibre}
                                    style={{
                                        margin:'10px',
                                        // paddingTop:'20px',
                                        height:'70px',
                                        // width:'100px'
                                    }}
                                alt='cellibre'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                {/* flyers */}
                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://drinkflyers.com/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={flyers}
                                    style={{
                                        margin:'0px',
                                        // paddingTop:'20px',
                                        height:'80px',
                                        // width:'100px'
                                    }}
                                alt='cellibre'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://www.happycabbage.io/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={hca}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'10px',
                                        width:'120px'
                                    }}
                                    alt='hca'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://henrysoriginal.com/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={henrys}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'20px',
                                        width:'120px'
                                    }}
                                    alt='henrys'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://www.iheartjane.com/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={jane}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'50px',
                                        width:'120px'
                                    }}
                                    alt='jane'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://www.leafly.com/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={leafly}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'20px',
                                        width:'120px'
                                    }}
                                    alt='leafly'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://www.nabis.com/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={nabis}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'20px',
                                        width:'120px'
                                    }}
                                    alt='nabis'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://trym.io/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={trym}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'40px',
                                        width:'120px'
                                    }}
                                    alt='trym'
                                ></img> 
                            </a>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://vangst.com/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={vangst}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'5px',
                                        width:'120px'
                                    }}
                                    alt='vangst'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>

                {/* <div className='col-md-4 mb-3 mb-md-0'>
                    <div className='py-4 h-100'>
                        <div className='card-body text-center'>
                            <a
                                href='https://www.weedweek.com/happy-cabbage-analytics-acquires-zoltrain-to-enhance-cannabis-retailer-profitability/'
                                target='blank'
                            >
                                <img
                                    className='zoom'
                                    src={zoltrain}
                                    style={{
                                        margin:'0px',
                                        paddingTop:'50px',
                                        width:'120px'
                                    }}
                                    alt='zoltrain'
                                ></img>
                            </a>
                        </div>
                    </div>
                </div>     */}
                
            </div>
        </div>
    )
  }
}


export default Porfolio;