import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Favicon from "react-favicon";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";
import "./styles/custom.css"


// --- main components
import Root__PAGE from "./pages/Root__PAGE"
import NavigationBar from "./components/NavigationBar";


// --- diligence pages
import DiligenceWelcome__PAGE from "./pages/diligence/DiligenceWelcome__PAGE";
import DiligenceIntroduction__PAGE from "./pages/diligence/DiligenceIntroduction__PAGE";
import DiligenceBusiness__PAGE from "./pages/diligence/DiligenceBusiness__PAGE";
import DiligenceFinance__PAGE from "./pages/diligence/DiligenceFinance__PAGE";
import DiligenceTechnology__PAGE from "./pages/diligence/DiligenceTechnology__PAGE";

// --- the firm pages
import OurObjective__PAGE from "./pages/the_firm/OurObjective__PAGE";
import OurPeople__PAGE from "./pages/the_firm/OurPeople__PAGE";
import Values__PAGE from "./pages/the_firm/Values__PAGE";
import OurPortfolio__PAGE from "./pages/the_firm/OurPortfolio__PAGE";
import FAQ__PAGE from "./pages/the_firm/FAQ__PAGE";

// --- what we do
import PrivateEquity__PAGE from "./pages/what_we_do/PrivateEquity__PAGE";
import ProprietaryData__PAGE from "./pages/what_we_do/ProprietaryData__PAGE";
import DigitalInfrastructure__PAGE from "./pages/what_we_do/DigitalInfrastructure__PAGE";

// --- insights
import MagicWand__PAGE from "./pages/insights/MagicWand__PAGE";
import ViewAll__PAGE from "./pages/insights/ViewAll__PAGE";


// --- other
import FounderTestimonials__PAGE from "./pages/other/FounderTestimonials__PAGE";
import Legal__PAGE from "./pages/other/Legal__PAGE";
import Privacy__PAGE from "./pages/other/Privacy__PAGE";
import DeltaDesigners__PAGE from "./pages/other/DeltaDesigners__PAGE";



// mike who cheese harry
// alpha kenny body


class App extends Component {
  render() {
    document.title = "Delta Emerald Ventures";
    return (
      <div>
        <NavigationBar />
        <Favicon url="https://i.imgur.com/nj3ADKp.png" />
        <Router>
          <Switch>
            {/* --- DILIGENCE --- */}
            <Route exact path="/diligence/welcome" component={DiligenceWelcome__PAGE} />
            <Route exact path="/diligence/introduction" component={DiligenceIntroduction__PAGE} />
            <Route exact path="/diligence/business" component={DiligenceBusiness__PAGE} />
            <Route exact path="/diligence/finance" component={DiligenceFinance__PAGE} />
            <Route exact path="/diligence/technology" component={DiligenceTechnology__PAGE} />

            {/* --- FIRM --- */}
            <Route exact path="/firm/objective" component={OurObjective__PAGE} />
            <Route exact path="/firm/people" component={OurPeople__PAGE} />
            <Route exact path="/firm/values" component={Values__PAGE} />
            <Route exact path="/firm/portfolio" component={OurPortfolio__PAGE} />
            <Route exact path="/firm/faq" component={FAQ__PAGE} />

            {/* --- WHAT WE DO --- */}
            <Route exact path="/what-we-do/private-equity" component={PrivateEquity__PAGE} />
            <Route exact path="/what-we-do/proprietary-data" component={ProprietaryData__PAGE} />
            <Route exact path="/what-we-do/digital-infrastructure" component={DigitalInfrastructure__PAGE} />

            {/* --- INSIGHTS --- */}
            <Route exact path="/insights/magic-wand" component={MagicWand__PAGE} />
            <Route exact path="/insights/view-all" component={ViewAll__PAGE} />

            
            
            
            {/* --- OTHER --- */}
            <Route exact path="/legal" component={Legal__PAGE} />
            <Route exact path="/privacy" component={Privacy__PAGE} />
            <Route exact path="/testimonials" component={FounderTestimonials__PAGE} />
            <Route exact path="/delta-designers" component={DeltaDesigners__PAGE} />
            

            {/* --- ROOT --- */}
            <Route exact path="/" component={Root__PAGE} />

          </Switch>
        </Router>
      </div>
    )
  }
}


export default App;
